import * as React from "react";
import styled from "styled-components";
import Layout from "../../../components/Layout/Layout.js";
import useContentful from "../../../utils/useContentful";
import MetaTags from "react-meta-tags";
import Hero from "../../../components/impacto/Hero.js";

import Navegation from "../../../components/impacto/Navegation.js";
import Buttonarrow from "../../../components/Botonarrow.js";

import "../../../styles/global.css";
import NotFoundPage from "../../404.js";

const Container = styled.div`
  position: relative;
  width: 100%;
  /*max-width: 1440px;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 78px;
`;

const Text = styled.div`
  width: 100%;
`;
const Cardcontent = styled.div`
  width: 90%;
  max-width: 1440px;
  display: grid;
  //grid-row-start: 1fr 1fr ;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;

  row-gap: 50px;
  justify-content: center;
  position: relative;
  z-index: 1;
  justify-items: center;

  @media (max-width: 900px) {
    width: 100%;
    grid-template-columns: 1fr;
    row-gap: 25px;
    column-gap: 16px;
    padding: 0px 30px;
  }
`;

const Cardcontainer = styled.div`
  position: relative;
  width: 384px;
  height: 465px;
  display: flex;
  flex-direction: column;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Foto = styled.div`
  height: 46%;
  width: 100%;
  background-image: url(${(p) => p.fotourl});
  border-radius: 4px;
  background-size: cover;

  :hover {
    box-shadow: 0px 0px 40px rgba(83, 202, 236, 0.7);
  }
`;

const Texto = styled.div`
  height: 54%;
  display: flex;
  flex-direction: column;

  h2 {
    font-weight: 800;
    margin-top: 32px;
    margin-bottom: 6px;
  }

  p {
    color: var(--gris);
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 8px;

  svg {
    margin-right: 4px;
  }
`;

const ButtonStyled = styled.a`
  position: absolute;
  z-index: 1;
  right: 25px;
  bottom: 224px;
`;

const Card = (props) => {


  return (
    <Cardcontainer>
      <Foto fotourl={props.imagen}></Foto>
      <Texto>
        <h2>{props.titulo}</h2>
        {/*<Row>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.5 12.5C7.5 12.9602 7.1269 13.3333 6.66667 13.3333C6.20643 13.3333 5.83333 12.9602 5.83333 12.5C5.83333 12.0398 6.20643 11.6667 6.66667 11.6667C7.1269 11.6667 7.5 12.0398 7.5 12.5Z" fill="#9E9E9E"/>
<path d="M7.5 9.16667C7.5 9.6269 7.1269 10 6.66667 10C6.20643 10 5.83333 9.6269 5.83333 9.16667C5.83333 8.70643 6.20643 8.33333 6.66667 8.33333C7.1269 8.33333 7.5 8.70643 7.5 9.16667Z" fill="#9E9E9E"/>
<path d="M10.8333 12.5C10.8333 12.9602 10.4602 13.3333 10 13.3333C9.53976 13.3333 9.16667 12.9602 9.16667 12.5C9.16667 12.0398 9.53976 11.6667 10 11.6667C10.4602 11.6667 10.8333 12.0398 10.8333 12.5Z" fill="#9E9E9E"/>
<path d="M10.8333 9.16667C10.8333 9.6269 10.4602 10 10 10C9.53976 10 9.16667 9.6269 9.16667 9.16667C9.16667 8.70643 9.53976 8.33333 10 8.33333C10.4602 8.33333 10.8333 8.70643 10.8333 9.16667Z" fill="#9E9E9E"/>
<path d="M14.1667 9.16667C14.1667 9.6269 13.7936 10 13.3333 10C12.8731 10 12.5 9.6269 12.5 9.16667C12.5 8.70643 12.8731 8.33333 13.3333 8.33333C13.7936 8.33333 14.1667 8.70643 14.1667 9.16667Z" fill="#9E9E9E"/>
<path fillRule="evenodd" clip-rule="evenodd" d="M10 3.95833C8.23611 3.95833 5.8262 4.11118 4.32208 4.22123C3.82708 4.25745 3.43704 4.64426 3.39684 5.13801C3.28083 6.56312 3.125 8.78477 3.125 10.4167C3.125 12.0486 3.28083 14.2702 3.39684 15.6953C3.43704 16.1891 3.82708 16.5759 4.32208 16.6121C5.8262 16.7222 8.23611 16.875 10 16.875C11.7639 16.875 14.1738 16.7222 15.6779 16.6121C16.1729 16.5759 16.563 16.1891 16.6032 15.6953C16.7192 14.2702 16.875 12.0486 16.875 10.4167C16.875 8.78477 16.7192 6.56312 16.6032 5.13801C16.563 4.64426 16.1729 4.25745 15.6779 4.22123C14.1738 4.11118 11.7639 3.95833 10 3.95833ZM4.23087 2.97457C5.73567 2.86446 8.18596 2.70833 10 2.70833C11.814 2.70833 14.2643 2.86446 15.7691 2.97457C16.8778 3.05568 17.7588 3.92773 17.849 5.03659C17.9651 6.46282 18.125 8.72915 18.125 10.4167C18.125 12.1042 17.9651 14.3705 17.849 15.7967C17.7588 16.9056 16.8778 17.7777 15.7691 17.8588C14.2643 17.9689 11.814 18.125 10 18.125C8.18596 18.125 5.73567 17.9689 4.23087 17.8588C3.12225 17.7777 2.24123 16.9056 2.15096 15.7967C2.03486 14.3705 1.875 12.1042 1.875 10.4167C1.875 8.72915 2.03486 6.46282 2.15096 5.03659C2.24123 3.92773 3.12225 3.05568 4.23087 2.97457Z" fill="#9E9E9E"/>
<path fillRule="evenodd" clip-rule="evenodd" d="M7.5 1.875C7.84518 1.875 8.125 2.15482 8.125 2.5V4.58333C8.125 4.92851 7.84518 5.20833 7.5 5.20833C7.15482 5.20833 6.875 4.92851 6.875 4.58333V2.5C6.875 2.15482 7.15482 1.875 7.5 1.875Z" fill="#9E9E9E"/>
<path fillRule="evenodd" clip-rule="evenodd" d="M12.5 1.875C12.8452 1.875 13.125 2.15482 13.125 2.5L13.125 4.58333C13.125 4.92851 12.8452 5.20833 12.5 5.20833C12.1548 5.20833 11.875 4.92851 11.875 4.58333L11.875 2.5C11.875 2.15482 12.1548 1.875 12.5 1.875Z" fill="#9E9E9E"/>
</svg>
<p> {props.fecha}</p>
<p> - </p>

    </Row>*/}
        <p> {props.tiempo} min de lectura</p>
        <p style={{ display: "flex" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            width="1.2em"
            height="1.2em"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 36 36"
          >
            <path
              fill="currentColor"
              d="M18 17a7 7 0 1 0-7-7a7 7 0 0 0 7 7Zm0-12a5 5 0 1 1-5 5a5 5 0 0 1 5-5Z"
              class="clr-i-outline clr-i-outline-path-1"
            />
            <path
              fill="currentColor"
              d="M30.47 24.37a17.16 17.16 0 0 0-24.93 0A2 2 0 0 0 5 25.74V31a2 2 0 0 0 2 2h22a2 2 0 0 0 2-2v-5.26a2 2 0 0 0-.53-1.37ZM29 31H7v-5.27a15.17 15.17 0 0 1 22 0Z"
              class="clr-i-outline clr-i-outline-path-2"
            />
            <path fill="none" d="M0 0h36v36H0z" />
          </svg>
          {props.autor}{" "}
        </p>
        <p>{props.content}</p>
      </Texto>
      <ButtonStyled target="_blank" href={props.url}>
        {" "}
        <Buttonarrow></Buttonarrow>
      </ButtonStyled>
    </Cardcontainer>
  );
};

const GeneralEntryId = "8zG9dMMm9VFjkRLfm9y3W";
const ImpactoEntry = "2oAZfLN7xDHsGeWDVtd6pk";

const IndexPage = () => {

return(
  <NotFoundPage></NotFoundPage>
)
 
{/*
  const { data: impacto, fetched } = useContentful({ id: ImpactoEntry });
  const { data: general, fetched: fetched2 } = useContentful({
    id: GeneralEntryId,
  });

  //console.log(impacto);
  // titulo={adopcion.fields.heroTitulo} prop example
  return (
    <>
      {fetched && fetched2 ? (
        <>
          <MetaTags>
            <title> {impacto.fields.pageTitle[3]} </title>
            <meta
              name={impacto.fields.metaDescription[3]}
              content={impacto.fields.metaContent[3]}
            />
            <link
              rel="canonical"
              href="https://www.aporta.org.pe/impacto/articulos/"
            />
            <link
              rel="alternate"
              hrefLang="x-default"
              href="https://www.aporta.org.pe/impacto/articulos/"
            />
          </MetaTags>

          <Layout
            margen={false}
            direccion={general.fields.direccion}
            facebook={general.fields.facebook}
            instagram={general.fields.instagram}
            linkedin={general.fields.linkedin}
            email={general.fields.email}
            telefono={general.fields.telefono}
            youtube={general.fields.youtube}
          >
            <Container>
              <Hero
                imagen={impacto.fields.headerImagenDeFondo.fields.file.url}
              ></Hero>
              <Navegation selection={"3"}></Navegation>
              <br></br>
              <br></br>
              <Cardcontent>
                {impacto.fields.articulos.map((field, index) => {
                  return (
                    <Card
                      autor={field.fields.autor}
                      content={field.fields.descripcion}
                      fecha={field.fields.fechaPublicacion}
                      imagen={field.fields.image.fields.file.url}
                      titulo={field.fields.titulo}
                      url={field.fields.url}
                      tiempo={field.fields.duracionLectura}
                    ></Card>
                  );
                })}
              </Cardcontent>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </Container>
          </Layout>
        </>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            padding: "10%",
          }}
        >
          {" "}
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBlackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path
              fill="var(--celeste)"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}
    </>
  );*/}
};

export default IndexPage;
